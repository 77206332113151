/**
 * IMPORTANT(trey): Do not import this file directly, instead use the convience variables provided
 * by `theme.scss`. Everytime this file is imported a new `:root` element will be created, leading
 * to CSS bloat.
 *
 * This section contains theme-aware colors from antd's light and dark themes, as well as Skydio's
 * own additional colors. Only colors listed here should be referenced, since otherwise they will
 * not properly update when the viewer switches themes.
 *
 * Note that if a color is the same in both themes, it can be added only to the root section and it
 * will also be available in dark mode.
 */

:root {
  // antd colors
  // https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
  // -----------------------------------------------------------------------------------------------
  --normal-color: #d9d9d9;
  --component-background: #fff;
  --border-color-base: hsv(0, 0, 85%);
  --background-color-base: hsv(0, 0, 96%);
  --background-color-skydio-admin: rgba(234, 34, 45, 0.1);

  --red-base: #f5222d;
  --red-5: #ff4d4f;

  --blue-base: #1890ff;
  --blue-6: var(--blue-base);

  --green-base: #52c41a;
  --green-6: var(--green-base);

  --gold-base: #faad14;
  --gold-6: var(--gold-base);

  --warning-color: var(--gold-6);
  --error-color: var(--red-5);
  --disabled-color: fade(#000, 25%);

  // skydio colors
  // -----------------------------------------------------------------------------------------------
  --primary-color-blur: rgba(0, 119, 238, 0.25);
  --layout-sider-background: #001529;
  --skydio-blue-faint: rgba(0, 119, 238, 0.1);
  --shadow-color: rgba(0, 0, 0, 0.15);
  --modal-background: rgba(0, 0, 0, 0.5);

  --icon-color-info: #096dd9;
  --icon-color-warn: #d4b106;
  --icon-color-error: #cf1322;

  --blue-1: #e6f7ff;
  --blue-2: #bae7ff;
  --blue-selected: #e4eafd;
  --green-valid: #73d13d;

  --grey-1: #ffffff;
  --grey-2: #fafafa;
  --grey-3: #f5f5f5;
  --grey-4: #e8e8e8;
  --grey-5: #d9d9d9;
  --grey-6: #bfbfbf;
  --grey-7: #8c8c8c;
  --grey-8: #595959;
  --grey-9: #262626;
  --grey-10: #000000;

  // Calculated using https://codepen.io/sosuke/pen/Pjoqqp
  // See https://stackoverflow.com/a/53336754
  --svg-primary: invert(0%) sepia(6%) saturate(7476%) hue-rotate(328deg) brightness(94%)
    contrast(106%);
}

// dark theme values
[data-theme="dark"] {
  // antd colors
  // https://github.com/ant-design/ant-design/blob/master/components/style/themes/dark.less
  // --------------------------------------------------------------------------------------------=
  --normal-color: #464646;
  --component-background: #141414;
  --border-color-base: #434343;
  --background-color-base: fade(#fff, 8%);

  --blue-1: #111d2c;
  --blue-2: #112a45;
  --blue-selected: #e4eafd;
  --green-valid: #73d13d;

  --red-5: #f96f77;

  --gold-6: #fbb937;

  --disabled-color: fade(#fff, 30%);

  // skydio colors
  // --------------------------------------------------------------------------------------------=
  // FIXME(trey): I made this up, this isn't an actual spec
  --layout-sider-background: #adb5bd;

  // FIXME(trey): I reversed these from light mode so it looks reasonable, this isn't an actual spec
  --grey-1: #000000;
  --grey-2: #262626;
  --grey-3: #595959;
  --grey-4: #8c8c8c;
  --grey-5: #bfbfbf;
  --grey-6: #d9d9d9;
  --grey-7: #e8e8e8;
  --grey-8: #f5f5f5;
  --grey-9: #fafafa;
  --grey-10: #ffffff;

  // Calculated using https://codepen.io/sosuke/pen/Pjoqqp
  // See https://stackoverflow.com/a/53336754
  --svg-primary: invert(100%) sepia(100%) saturate(1%) hue-rotate(346deg) brightness(105%)
    contrast(102%);
}
