/******************************************************************************
 breakpoints
******************************************************************************/
$largeScreenHeight: 700; /* [px] use smallScreen layout if less than this value */
$largeScreenWidth: 1000; /* [px] use smallScreen layout if less than this value */
/* Sass variables don't work directly in media queries. :^\ Update these values to match
   $largeScreenHeight and $largeScreenWidth */
$isLargeScreen: "(min-height: 700px) and (min-width: 1000px)";

:export {
  largeScreenHeight: $largeScreenHeight;
  largeScreenWidth: $largeScreenWidth;
}

/******************************************************************************
 border
******************************************************************************/
.ant-btn-default,
.ant-btn-default:hover,
.ant-btn-default:active,
.ant-btn-default:focus,
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-text,
.ant-btn-text:hover,
.ant-btn-text:active,
.ant-btn-text:focus,
.ant-collapse-borderless > .ant-collapse-item,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-style: none;
  border-width: 0;
}
.ant-alert,
.ant-btn.bordered,
.bordered,
.labeled-switch {
  border-style: solid;
  border-width: 1px;
}
.thumbnail-container {
  border-style: solid;
  border-width: 4px;
}
/******************************************************************************
 border-collapse
******************************************************************************/
.ant-table table,
table {
  border-collapse: collapse;
}
/******************************************************************************
 border-radius
******************************************************************************/
.group .ant-btn,
.group .ant-select.ant-select-single.ant-select-show-arrow,
.group .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.viewer-shortcut-buttons .ant-btn {
  border-radius: 0;
}
.ant-select.ant-select-single.ant-select-show-arrow,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 2px;
}
.group > :first-child > *,
.group > :first-child > [class*="ant-"],
.group > :first-child > [class*="ant-"] .ant-select-selector {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.group > :last-child > *,
.group > :last-child > [class*="ant-"],
.group > :last-child > [class*="ant-"] .ant-select-selector {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.viewer-shortcut-buttons {
  border-radius: 4px;
}
.colormap-legend {
  border-radius: 6px;
}
/******************************************************************************
 display
******************************************************************************/
.list-position {
  display: inline-block;
}
/******************************************************************************
 flex
******************************************************************************/
.colormap-legend > .colors,
.flex,
.group,
.labeled-switch,
.layout,
.layout-content,
.menubar,
.split,
.split .gutter,
.viewer-thumbnail-panel,
.viewer-shortcut-buttons {
  align-content: normal;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.flex.inline,
.labeled-switch {
  align-items: center;
}
.colormap-legend > .colors,
.flex.stretch-content {
  align-content: stretch;
}
.flex.center,
.group,
.menubar,
.split .gutter,
.viewer-shortcut-buttons {
  align-content: center;
  align-items: center;
  justify-content: center;
}
.flex.flex-start,
.viewer-thumbnail-panel {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex.flex-end {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex.column,
.group.column,
.layout,
.split.vertical {
  flex-direction: column;
}
.group,
.layout,
.menubar,
.no-wrap,
.split,
.viewer-shortcut-buttons {
  flex-wrap: nowrap;
}
.flex-freeze,
.labeled-switch > .help,
.labeled-switch > .switch,
.layout-footer,
.layout-header,
.split .gutter {
  flex: 0 0 auto;
}
.colormap-legend > .colors > div,
.flex-fill,
.full,
.full-height,
.full-width,
.labeled-switch > .label,
.layout,
.layout-content,
.panel,
.split,
.thumbnail-container {
  flex: 1 1 auto;
}
/******************************************************************************
 grid
******************************************************************************/
.colormap-legend {
  align-content: stretch;
  align-items: stretch;
  display: grid;
  gap: 0;
  grid-template-areas:
    "title title title"
    "min mid max"
    "colors colors colors";
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: auto;
  justify-content: stretch;
  justify-items: stretch;
}
.colormap-legend > .title {
  grid-area: title;
}
.colormap-legend > .max {
  grid-area: max;
}
.colormap-legend > .mid {
  grid-area: mid;
}
.colormap-legend > .min {
  grid-area: min;
}
.colormap-legend > .colors {
  grid-area: colors;
}
/******************************************************************************
 height, width, min, max
******************************************************************************/
.colormap-legend > .colors > div,
.full,
.full-height,
.layout,
.panel,
.split {
  height: 100%;
  max-height: 100%;
  min-height: 0;
}
.ant-select.ant-select-single.ant-select-show-arrow,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.camera-view-navigator,
.collapsible-meta-list,
.colormap-legend > .colors > .coverage-heatmap,
.full,
.full-width,
.info,
.labeled-switch,
.layout,
.menubar,
.meta-list,
.panel,
.split,
.thumbnail {
  max-width: 100%;
  min-width: 0;
  width: 100%;
}
.colormap-legend > .colors > .binary-coverage {
  width: 50%;
}
.colormap-legend {
  width: 12em;
}
.ant-btn,
.ant-btn-icon-only {
  height: 2em;
  text-align: center !important;
}
.ant-btn-icon-only {
  width: 2em;
}
.ant-btn-default.ant-btn-icon-only {
  height: 2.6em;
  width: 2.6em;
}
.ant-slider,
.colormap-legend > .colors,
.slider-icon {
  height: 1em;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 2em;
}
.thumbnail {
  height: auto;
}
// max-width needs to be (2 * min-width)-1 so thumbnails fill gallery width
.thumbnail-container {
  max-width: 399px;
  min-width: 200px;
  width: 200px;
}
/***
   START Safari height bug. Remove when no longer needed.
   Branch: https://gerrit.skyd.io/c/aircam/+/29328
   NOTE(rachel): Good summary:
   https://stackoverflow.com/questions/33636796/chrome-safari-not-filling-100-height-of-flex-parent/33644245#33644245
   We can't avoid ever setting heights because that's how the resizable panels work.
   Absolute positioning lots of boxes creates other headaches. I went with just setting explicit
   heights on the missing links. This is done with id selectors on affected elements and CSS media
   queries to accommodate small vs large screen differences. The fix is isolated for easy removal
   and is pure CSS so not a performance issue and has no effect on React lifecycle. Safari or the
   W3C really should fix this behavior, but it's been an issue for 5+ years, so it might be around
   for a while.
*/
$safari-height-bug-single-header-footer-height: 3em;
$safari-height-bug-double-header-footer-height: 5em;
.safari-height-bug-app-layout-header {
  height: $safari-height-bug-single-header-footer-height;
}
.safari-height-bug-app-layout-content {
  height: calc(100% - #{$safari-height-bug-single-header-footer-height});
}
.safari-height-bug-panels-layout-footer {
  height: $safari-height-bug-double-header-footer-height;
}
.safari-height-bug-panels-layout-content {
  height: calc(100% - #{$safari-height-bug-double-header-footer-height});
}
@media #{$isLargeScreen} {
  .safari-height-bug-panels-layout-footer {
    height: $safari-height-bug-single-header-footer-height;
  }
  .safari-height-bug-panels-layout-content {
    height: calc(100% - #{$safari-height-bug-single-header-footer-height});
  }
}
// On removal, keep this rule and just replace variable
.layout-footer,
.layout-header,
.menubar:not(.tabs) {
  min-height: $safari-height-bug-single-header-footer-height;
}
/* END Safari height bug */
/******************************************************************************
 margin
******************************************************************************/
.ant-drawer-close,
.group > * > *,
.group > * > .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.viewer-shortcut-buttons {
  margin: 0;
}
.colormap-legend > div {
  margin: 0.2em;
}
.labeled-switch > *,
li,
.spaced,
.spaced-children > * {
  margin: 0.4em;
}
.anticon {
  margin: 0 auto;
}
.ant-notification-notice-description,
.group.spaced {
  margin: 0.4em 0;
}
.menubar > div {
  margin: 0 0.4em;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  margin: 0.25em 0;
}
.camera-view-navigator > .ant-btn,
.camera-view-navigator > .list-position {
  margin: 0 0.2em;
}
.ant-list-sm .ant-list-item {
  margin-bottom: 0.4em;
}
.slider-icon {
  margin-top: 0.5em;
}
.section {
  margin-bottom: 1.2em;
  margin-top: 1.2em;
}
.elbow {
  margin-left: 1em;
  margin-right: 1em;
}
// font-size is set to 0 on .ant-list-item-action for some very good reason I'm sure, so the
// ridiculously large left margin can't be reset relatively.
.ant-list-item-action,
.elbow-left {
  margin-left: 1em;
}
.elbow-right {
  margin-right: 1em;
}
.collapsible-meta-list,
.meta-list,
.spaced-full {
  margin: 1em;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  margin: 0.5em 0;
}
.ant-slider {
  margin: 0.5em;
}
.ant-slider.ant-slider-with-marks {
  margin: 0.5em 0.5em 2em;
}
.ant-typography h1,
.ant-typography h2,
.ant-typography h3,
.ant-typography h4,
.ant-typography h5,
.ant-typography h6,
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1em 0 0.4em;
}
.ant-typography h1:first-child,
.ant-typography h2:first-child,
.ant-typography h3:first-child,
.ant-typography h4:first-child,
.ant-typography h5:first-child,
.ant-typography h6:first-child,
h1.ant-typography:first-child,
h2.ant-typography:first-child,
h3.ant-typography:first-child,
h4.ant-typography:first-child,
h5.ant-typography:first-child,
h6.ant-typography:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}
/******************************************************************************
 overflow
******************************************************************************/
.ant-drawer-content {
  overflow: visible;
}
.ant-drawer-body,
.ant-modal-content,
.viewer-thumbnail-panel {
  overflow: auto;
}
.split .gutter {
  overflow: hidden;
}
/******************************************************************************
 padding
******************************************************************************/
.ant-collapse-content > .ant-collapse-content-box,
.ant-list-header,
.ant-list-sm .ant-list-item,
.ant-select-dropdown {
  padding: 0;
}
.ant-list-items {
  padding: 0 0 0 0.5em;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 0 0 1.5em;
}
.padded {
  padding: 1em;
}
.ant-btn,
.colormap-legend,
.menubar {
  padding: 0.4em;
}
.menubar.tabs {
  padding: 0 0.4em;
}
.ant-btn.ant-btn-icon-only,
.ant-drawer-close {
  padding: 0.2em;
}
.ant-drawer-body,
.ant-drawer-header {
  padding: 1em;
}
ol,
ul {
  padding-left: 1.5em;
}
/******************************************************************************
 position, bottom, left, right, top
******************************************************************************/
.cesium-widget-credits {
  position: static;
}
.layout,
.layout-content,
.layout-footer,
.layout-header,
.overlay-container,
.panel,
.thumbnail-container {
  position: relative;
}
.colormap-legend,
.loading-indicator,
.overlay,
.viewer-shortcut-buttons {
  position: absolute;
}
.overlay {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.colormap-legend {
  bottom: 8px;
  left: 8px;
  right: auto;
  top: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0;
  top: 0.4em;
}
.loading-indicator {
  bottom: auto;
  left: calc(50% - 1.8em);
  right: auto;
  top: calc(50% - 1.8em);
}
.viewer-shortcut-buttons.bottom-left {
  bottom: 6px;
  left: 6px;
  right: auto;
  top: auto;
}
.viewer-shortcut-buttons.bottom-right {
  bottom: 6px;
  left: auto;
  right: 6px;
  top: auto;
}
.viewer-shortcut-buttons.top-right {
  bottom: auto;
  left: auto;
  right: 6px;
  top: 6px;
}
/******************************************************************************
 z-index
 Some antd values for reference:
   $zindex-popup-close: 10;
   $zindex-modal: 1000;
   $zindex-modal-mask: 1000;
   $zindex-message: 1010;
   $zindex-notification: 1010;
   $zindex-popover: 1030;
   $zindex-dropdown: 1050;
   $zindex-picker: 1050;
   $zindex-popoconfirm: 1060;
   $zindex-tooltip: 1070;
   $zindex-image: 1080;
******************************************************************************/
.colormap-legend,
.loading-indicator,
.overlay {
  z-index: 1;
}
.split .gutter,
.viewer-shortcut-buttons {
  z-index: 2;
}
