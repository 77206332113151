@import "./vars/colors";

/******************************************************************************
 background-color, color
******************************************************************************/
/* Reset elements to not set their own background-color or color.
  background-color and color should be set:
  - together to ensure text visibility,
  - on as few elements as possible, for simplicity and consistency and to prevent semi-translucent
    backgrounds from breaking (layers add),
  - on parent/container elements like body, drawer, modal, etc.
    instead of on content elements like paragraphs, headers, list items, form elements, etc.,
    which should inherit settings from an ancestor.
 */
.ant-btn-text:hover,
.ant-btn-text:active,
.ant-btn-text:focus,
.ant-collapse > .ant-collapse-item > .ant-collapse-header,
.ant-drawer-close:hover,
.ant-drawer-close:active,
.ant-drawer-close:focus,
.ant-form-item-label > label,
.ant-list-item-action > li,
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr.ant-table-row:active > td,
.ant-table-tbody > tr.ant-table-row:focus > td,
.ant-typography,
.anticon,
div,
h1,
h2,
h3,
h4,
h5,
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  @include color-defaults();
}
.ant-drawer-mask {
  background-color: transparent;
  color: transparent;
}
.ant-modal-close-x:hover,
.ant-modal-close-x:active,
.ant-modal-close-x:focus,
.loading-indicator {
  background-color: transparent;
  color: $white;
}
.viewer-root,
.viewer-navbar,
.viewer-titlebar {
  background-color: $black;
  color: $white;
}
.colormap-legend {
  background-color: $black-faint;
  color: $white-translucent;
}
.ant-btn-text[disabled]:not(.panel-tab),
.ant-btn-text[disabled]:not(.panel-tab):hover,
.ant-btn-text[disabled]:not(.panel-tab):focus,
.ant-btn-text[disabled]:not(.panel-tab):active {
  background-color: $black-faint;
  color: $gray-lightest;
}
.ant-drawer-content-wrapper,
.panel,
.viewer-panels {
  background-color: $gray-darkest;
  color: $white;
}
.ant-btn-default,
.ant-btn-default:hover,
.ant-btn-default:active,
.ant-btn-default:focus,
// Need to override customer portal's css.
.ant-btn-default[disabled],
.ant-btn-default[disabled]:hover,
.ant-btn-default[disabled]:focus,
.ant-btn-default[disabled]:active,
.gray-darker {
  background-color: $gray-darker;
  color: $white;
}
.ant-select-dropdown,
.ant-select-item,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-tooltip-inner,
.split .gutter {
  background-color: $gray;
  color: $white;
}
.ant-message-notice-content,
.ant-notification-hook-holder,
.ant-notification-notice,
.ant-popover-inner,
.ant-popover-inner-content,
.ant-tooltip-arrow-content {
  background-color: $gray-lighter;
  color: $white;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option:hover,
.ant-select-item-option:active,
.ant-select-item-option:focus {
  background-color: $gray-lightest-translucent;
  color: $white;
}
.ant-select-arrow {
  color: $white;
}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: $skydio-blue-darker-translucent;
  color: $white;
}
.ant-btn-text[disabled].panel-tab,
.ant-btn-text[disabled].panel-tab:hover,
.ant-btn-text[disabled].panel-tab:focus,
.ant-btn-text[disabled].panel-tab:active {
  background-color: $white;
  color: $black;
}
.ant-message-error .anticon {
  color: $error;
}
.ant-message-info .anticon {
  color: $info;
}
.ant-message-success .anticon {
  color: $success;
}
.ant-message-warning .anticon {
  color: $warning;
}
.colormap-legend .colors .coverage-heatmap {
  background: $viridis-background;
  background-image: $viridis-gradient;
}
.colormap-legend .colors .binary-coverage.max {
  background-color: $viridis-max;
}
.colormap-legend .colors .binary-coverage.min {
  background-color: $viridis-min;
}
.green {
  color: $green;
}
.orange {
  color: $orange;
}
.purple {
  color: $purple;
}
.red {
  color: $red;
}
.enabled,
.enabled:hover,
.enabled:focus,
.enabled:active {
  background-color: $white-half;
}
.disabled:not(.thumbnail-container) {
  background-color: $black-shadow;
}
.ant-form-item-control-input-content,
.ant-table-row:hover,
.ant-table-row:active,
.ant-table-row:focus,
.ant-typography code,
.ant-typography kbd,
code,
kbd {
  background-color: $white-shadow;
}
/******************************************************************************
 border-color
******************************************************************************/
.ant-btn-primary,
.ant-btn-text.bordered,
.ant-divider,
.ant-drawer-header,
.ant-table-tbody > tr > td,
.bordered {
  border-color: initial;
}
.ant-typography code,
.ant-typography kbd,
code,
kbd {
  border-color: $white-faint;
}
.thumbnail-container {
  border-color: $gray-darkest;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-left-color: $gray-lighter;
  border-top-color: $gray-lighter;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  border-right-color: $gray-lighter;
  border-top-color: $gray-lighter;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: $gray-lighter;
  border-left-color: $gray-lighter;
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: $gray-lighter;
  border-right-color: $gray-lighter;
}
.thumbnail-container.activeCameraView,
.thumbnail-container:hover,
.thumbnail-container:active,
.thumbnail-container:focus {
  border-color: $white;
}
.labeled-switch {
  border-color: $white-shadow;
}
.ant-alert-error {
  border-color: $error;
}
.ant-alert-info {
  border-color: $info;
}
.ant-alert-success {
  border-color: $success;
}
.ant-alert-warning {
  border-color: $warning;
}
/******************************************************************************
 box-shadow
******************************************************************************/
.ant-modal-content,
[class*="ant-drawer"].ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus,
.ant-btn-default:hover,
.ant-btn-default:active,
.ant-btn-default:focus,
.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-text:hover,
.ant-btn-text:active,
.ant-btn-text:focus,
.ant-select-selector:hover,
.ant-select-selector:active,
.ant-select-selector:focus {
  // a better alternative to outline
  box-shadow: 0 0 0 1px $white-half;
}
/******************************************************************************
 opacity
******************************************************************************/
.ant-select-selection-placeholder {
  opacity: 0.9;
}
.ant-divier,
.secondary {
  opacity: 0.75;
}
.ant-btn-default[disabled],
.ant-btn-default[disabled]:hover,
.ant-btn-default[disabled]:focus,
.ant-btn-default[disabled]:active,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.disabled:not(.panel-tab):not(.thumbnail-container) {
  opacity: 0.6;
}
/******************************************************************************
 outline-color
******************************************************************************/
.ant-btn,
.ant-select-selector {
  outline-color: $white-translucent;
}
/******************************************************************************
 misc
******************************************************************************/
.app-name.white {
  color: $white;
}
.ant-slider {
  background-color: transparent;
  color: $white;
}
.ant-slider-rail,
.ant-switch {
  background-color: $gray;
}
.ant-slider:hover .ant-slider-rail,
.ant-slider:active .ant-slider-rail,
.ant-slider:focus .ant-slider-rail {
  background-color: $gray-lighter;
}
.ant-slider-track {
  background-color: $skydio-blue;
}
.ant-slider:hover .ant-slider-track,
.ant-slider:active .ant-slider-track,
.ant-slider:focus .ant-slider-track {
  background-color: $skydio-blue-lighter;
  border-color: $skydio-blue-lighter;
}
.ant-slider-dot {
  background-color: $white;
  border-color: $skydio-blue;
}
.ant-slider-dot.ant-slider-dot-active {
  background-color: $white;
  border-color: $skydio-blue-lighter;
}
.ant-slider-handle {
  background-color: $white;
  border-color: $skydio-blue;
}
.ant-switch.ant-switch-checked {
  background-color: $skydio-blue;
}
.ant-switch-handle:before {
  background-color: $white;
}
