@import "./vars/colors";
@import "./mixins/colors";
@import "./mixins/typography";
@import "./mixins/modal";

.viewer-modal {
  line-height: inherit;
  @include color-defaults();
  @include typography-defaults();
  @import "core";

  .meta-list {
    [class*="ant-"] {
      @include viewer-modal-styles();
    }
  }

  .ant-form {
    @include viewer-modal-styles();

    // Exclude ant buttons from getting styles
    [class*="ant-"]:not([class*="ant-btn"]) {
      @include viewer-modal-styles();
    }

    // Target specific ant buttons needed with required styles
    .file-download-button {
      line-height: inherit;
      text-align: left;
      font-size: inherit;
    }
  }

  .scan-settings-menu {
    .secondary {
      [class*="ant-"] {
        @include viewer-modal-styles();
      }
    }
  }

  // Help Modal, colors in cloud don't match that in onboard
  .ant-table-cell {
    .ant-typography {
      kbd {
        color: inherit;
      }
    }
  }

  .ant-btn {
    text-align: center;
  }

  &.ant-modal,
  .ant-modal-header {
    border-color: $gray-lighter;
    text-align: center;
  }

  &.ant-modal {
    border-style: solid;
    border-width: 2px;
    background-color: $gray-darkest;
    color: $white;
    line-height: 1;

    // For smaller screens, we reduce the space above the modal
    @media only screen and (max-height: 480px) {
      top: 10px;
    }

    .ant-modal-title {
      font-size: 1.28em;
      font-weight: 600;
      line-height: inherit;
      text-align: center;
    }

    .ant-modal-body {
      line-height: 1;
      text-align: left;
    }

    .ant-typography {
      text-align: left;
      line-height: inherit;
    }

    .ant-modal-close-x {
      height: 50px;
      line-height: 50px;
      width: 50px;
    }
  }
}
