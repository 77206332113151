@import "./mixins/typography";

:link,
:visited {
  text-decoration: underline;
}
/* Prevent Safari auto-zoom on active/focused inputs. Safari will auto zoom iff font-size
   evaluates to less than 16px. Add #root for selector specificity. Interpolate to use
   CSS's min rather than Sass'. */
.ant-input,
.ant-select,
.ant-select-dropdown {
  font-size: 16px !important; // max not working in Firefox
  font-size: #{"max(1em, 16px)"} !important;
}
.smallest,
sub,
.subscript,
sup,
.superscript {
  font-size: 0.6em;
}
.smaller {
  font-size: 0.8em;
}
.ant-btn-icon-only.ant-btn-sm,
.ant-typography h6,
.info,
.small,
h6,
h6.ant-typography {
  font-size: 0.9em;
}
.ant-list-item-action > li,
.ant-typography h5,
.ant-btn-icon-only,
.ant-list-header,
.medium,
h5,
h5.ant-typography {
  font-size: 1em;
}
.ant-collapse-header,
.ant-typography h4,
.large,
.loading-indicator,
h4,
h4.ant-typography {
  font-size: 1.18em;
}
.ant-drawer-title,
.ant-typography h3,
.larger,
h3,
h3.ant-typography,
.menubar .title {
  font-size: 1.28em;
}
.ant-notification-notice-icon,
.ant-typography h2,
h2,
h2.ant-typography {
  font-size: 1.6em;
}
.ant-typography h1,
h1,
h1.ant-typography {
  font-size: 2em;
}
.ant-btn-icon-only.ant-btn-lg {
  font-size: 1.16em;
}
// override antd's absolute sizes for icon buttons
// FA sizes: https://fontawesome.com/how-to-use/on-the-web/styling/sizing-icons
.ant-btn-icon-only > .fa-xs,
.ant-btn-icon-only.ant-btn-lg > .fa-xs,
.ant-btn-icon-only.ant-btn-sm > .fa-xs {
  font-size: 0.75em;
}
.ant-btn-icon-only > .fa-sm,
.ant-btn-icon-only.ant-btn-lg > .fa-sm,
.ant-btn-icon-only.ant-btn-sm > .fa-sm {
  font-size: 0.875em;
}
.ant-btn-icon-only > .fa-lg,
.ant-btn-icon-only.ant-btn-lg > .fa-lg,
.ant-btn-icon-only.ant-btn-sm > .fa-lg {
  font-size: 1.33em;
}
.ant-btn-icon-only > .fa-2x,
.ant-btn-icon-only.ant-btn-lg > .fa-2x,
.ant-btn-icon-only.ant-btn-sm > .fa-2x {
  font-size: 2em;
}
.ant-btn-icon-only > .fa-3x,
.ant-btn-icon-only.ant-btn-lg > .fa-3x,
.ant-btn-icon-only.ant-btn-sm > .fa-3x {
  font-size: 3em;
}
.ant-btn-icon-only > .fa-4x,
.ant-btn-icon-only.ant-btn-lg > .fa-4x,
.ant-btn-icon-only.ant-btn-sm > .fa-4x {
  font-size: 4em;
}
.ant-btn-icon-only > .fa-5x,
.ant-btn-icon-only.ant-btn-lg > .fa-5x,
.ant-btn-icon-only.ant-btn-sm > .fa-5x {
  font-size: 5em;
}
.ant-btn-icon-only > .fa-6x,
.ant-btn-icon-only.ant-btn-lg > .fa-6x,
.ant-btn-icon-only.ant-btn-sm > .fa-6x {
  font-size: 6em;
}
.ant-btn-icon-only > .fa-7x,
.ant-btn-icon-only.ant-btn-lg > .fa-7x,
.ant-btn-icon-only.ant-btn-sm > .fa-7x {
  font-size: 7em;
}
.ant-btn-icon-only > .fa-8x,
.ant-btn-icon-only.ant-btn-lg > .fa-8x,
.ant-btn-icon-only.ant-btn-sm > .fa-8x {
  font-size: 8em;
}
.ant-btn-icon-only > .fa-9x,
.ant-btn-icon-only.ant-btn-lg > .fa-9x,
.ant-btn-icon-only.ant-btn-sm > .fa-9x {
  font-size: 9em;
}
.ant-btn-icon-only > .fa-10x,
.ant-btn-icon-only.ant-btn-lg > .fa-10x,
.ant-btn-icon-only.ant-btn-sm > .fa-10x {
  font-size: 10em;
}
em,
.id-string,
.italic {
  font-style: italic;
}
.ant-btn-primary,
.ant-collapse-header,
.ant-drawer-title,
.ant-list-header,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-typography h1,
.ant-typography h2,
.ant-typography h3,
.ant-typography h4,
.ant-typography h5,
.ant-typography h6,
.app-name,
.bold,
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography,
h1,
h2,
h3,
h4,
h5,
h6,
.list-position,
.title {
  font-weight: 600;
}
.ant-slider-mark,
.ant-typography,
.app-name,
ol,
p,
.text,
ul {
  line-break: normal;
}
.ant-list,
.heading,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
table,
.title,
ul {
  line-height: 1.4;
}
.ant-alert,
.text,
p {
  line-height: 1.6;
}
.ant-alert,
.ant-popover,
.ant-select,
.ant-slider-mark,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td,
.ant-typography,
.text {
  overflow-wrap: normal;
}
.left-aligned,
.menubar .title {
  text-align: left;
}
.ant-btn,
.ant-drawer-close,
.ant-message-notice,
.camera-view-navigator,
.center-aligned,
.colormap-legend,
.list-position,
.loading-indicator,
.menubar {
  text-align: center;
}
.right-aligned {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
.ant-table,
table,
td,
th {
  vertical-align: middle;
}
.ant-alert,
.ant-typography,
ol,
p,
span,
.text,
.title,
ul {
  vertical-align: baseline;
}
.valign-middle-icon {
  vertical-align: 15%;
}
code,
pre {
  white-space: pre;
}
.ant-alert,
.ant-notification,
.ant-popover,
.ant-select,
.ant-slider-mark,
.ant-typography,
.list-position,
.text,
ol,
p,
ul {
  word-break: normal;
}
.app-name {
  word-break: keep-all;
}
.ant-btn {
  word-spacing: -0.02em;
}
.ant-alert,
.ant-notification,
.ant-popover,
.ant-select,
.ant-slider-mark,
.ant-typography,
.app-name,
ol,
p,
.text,
ul {
  word-wrap: normal;
}
