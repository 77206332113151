/******************************************************************************
 Colors

 Standard tint/shade postfixes: -lighter, -lightest, -darker, -darkest
 Standard alpha postfixes: -translucent: ~0.85, -half: ~0.5, -faint: ~0.2, -shadow: ~0.1
******************************************************************************/
// Skydio blue, primary color
$skydio-blue: rgb(0, 119, 238);
$skydio-blue-translucent: rgba(0, 119, 238, 0.85);
$skydio-blue-half: rgba(0, 119, 238, 0.5);
$skydio-blue-faint: rgba(0, 119, 238, 0.2);
$skydio-blue-lightest: rgb(200, 220, 255);
$skydio-blue-lightest-translucent: rgba(200, 220, 255, 0.85);
$skydio-blue-lightest-half: rgba(200, 220, 255, 0.5);
$skydio-blue-lighter: rgb(30, 170, 255);
$skydio-blue-lighter-translucent: rgba(30, 170, 255, 0.85);
$skydio-blue-darker: rgb(5, 100, 200);
$skydio-blue-darker-translucent: rgba(5, 100, 200, 0.85);
$skydio-blue-darker-half: rgba(5, 100, 200, 0.5);
$skydio-blue-darker-faint: rgba(5, 100, 200, 0.2);
$skydio-blue-darkest: rgb(0, 50, 100);
$skydio-blue-darkest-translucent: rgba(0, 50, 100, 0.85);
$skydio-blue-darkest-half: rgba(0, 50, 100, 0.5);
$skydio-blue-darkest-faint: rgba(0, 50, 100, 0.2);

// Gray, secondary color
$gray: rgb(77, 77, 77);
$gray-translucent: rgba(77, 77, 77, 0.85);
$gray-lightest: rgb(150, 150, 150);
$gray-lightest-translucent: rgba(150, 150, 150, 0.85);
$gray-lighter: rgb(110, 110, 110);
$gray-lighter-translucent: rgba(110, 110, 110, 0.85);
$gray-lighter-half: rgba(110, 110, 110, 0.5);
$gray-lighter-faint: rgba(110, 110, 110, 0.2);
$gray-darker: rgb(55, 55, 55);
$gray-darker-translucent: rgba(55, 55, 55, 0.85);
$gray-darker-half: rgba(55, 55, 55, 0.5);
$gray-darker-faint: rgba(55, 55, 55, 0.2);
$gray-darkest: rgb(34, 34, 34);
$gray-darkest-translucent: rgba(34, 34, 34, 0.85);
$gray-darkest-half: rgba(34, 34, 34, 0.5);
$gray-darkest-faint: rgba(34, 34, 34, 0.2);

// Other
$black: rgb(0, 0, 0);
$black-translucent: rgba(0, 0, 0, 0.85);
$black-half: rgba(0, 0, 0, 0.5);
$black-faint: rgba(0, 0, 0, 0.2);
$black-shadow: rgba(0, 0, 0, 0.1);
$green: rgb(40, 180, 100);
$green-half: rgba(40, 180, 100, 0.5);
$green-faint: rgba(40, 180, 100, 0.2);
$orange: rgb(200, 100, 10);
$purple: rgb(180, 10, 200);
$red: rgb(200, 40, 40);
$red-translucent: rgba(200, 40, 40, 0.85);
$red-half: rgba(200, 40, 40, 0.5);
$red-faint: rgba(200, 40, 40, 0.2);
$skydio-yellow: rgb(255, 190, 24);
$skydio-yellow-translucent: rgba(255, 190, 24, 0.85);
$skydio-yellow-half: rgba(255, 190, 24, 0.5);
$skydio-yellow-faint: rgba(255, 190, 24, 0.2);
$skydio-yellow-overlay: rgb(255, 255, 53);
$skydio-yellow-overlay-translucent: rgba(255, 255, 53, 0.85);
$skydio-yellow-overlay-half: rgba(255, 255, 53, 0.5);
$skydio-yellow-overlay-faint: rgba(255, 255, 53, 0.2);
$skydio-yellow-overlay-shadow: rgba(255, 255, 53, 0.1);
$white: rgb(255, 255, 255);
$white-translucent: rgba(255, 255, 255, 0.85);
$white-half: rgba(255, 255, 255, 0.5);
$white-faint: rgba(255, 255, 255, 0.2);
$white-shadow: rgba(255, 255, 255, 0.1);

$error: $red;
$info: $purple;
$success: $green;
$warning: $orange;

// Colormaps, from https://bennettfeely.com/scales/
$viridis-background: rgb(74, 125, 112);
$viridis-max: rgb(68, 1, 84);
$viridis-min: rgb(189, 223, 38);
$viridis-gradient: linear-gradient(
  270deg,
  rgb(68, 1, 84),
  rgb(72, 36, 117),
  rgb(65, 68, 135),
  rgb(53, 95, 141),
  rgb(42, 120, 142),
  rgb(33, 144, 141),
  rgb(34, 168, 132),
  rgb(66, 190, 113),
  rgb(122, 209, 81),
  rgb(189, 223, 38),
  rgb(255, 255, 255)
);
