@import "../vars/colors";

@mixin color-defaults {
  background-color: transparent;
  color: inherit;
}

@mixin root-colors {
  background-color: $gray-darkest;
  color: $white;
}
