/******************************************************************************
 cursor
******************************************************************************/
.split.horizontal .gutter {
  cursor: col-resize;
}
.split.vertical .gutter {
  cursor: row-resize;
}
.ant-btn-default[disabled],
.ant-btn-default[disabled]:hover,
.ant-btn-default[disabled]:focus,
.ant-btn-default[disabled]:active,
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active,
.disabled {
  cursor: not-allowed;
}
.ant-btn:not(.disabled),
.ant-select:not(.disabled),
a:not(.disabled),
button:not(.disabled),
.thumbnail-container:not(.disabled) {
  cursor: pointer;
  // TODO: Remove the following rule once we found the problem with icons color on master:
  color: white;
}
#viewer-image-panel {
  cursor: grab;
}
/******************************************************************************
 pointer-events
******************************************************************************/
svg {
  pointer-events: none;
}
/******************************************************************************
 touch-action
******************************************************************************/
.split.horizontal .gutter {
  touch-action: pan-x;
}
.split.vertical .gutter {
  touch-action: pan-y;
}

/* Prevent Safari auto-zoom on active/focused inputs. Safari will auto zoom iff font-size
   evaluates to less than 16px. Interpolate to use CSS's min rather than Sass'. */
input,
select,
textarea {
  font-size: 16px !important; // max not working in Firefox
  font-size: #{"max(1em, 16px)"} !important;
}

/* Prevent select of viewers on long press. */
#image-viewer,
#model-viewer,
#viewer-image-panel,
#viewer-model-panel {
  user-select: none;
}
