@import "./vars/colors";

/******************************************************************************
 Responsive aspect-ratio box
 see: https://css-tricks.com/aspect-ratio-boxes/#using-custom-properties
******************************************************************************/
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
/******************************************************************************
 react-split gutter
******************************************************************************/
/* handles for drag affordance  */
.split .gutter:before {
  display: block;
  font-size: 20px;
  height: auto;
  line-height: 1em;
  margin: 0;
  text-align: center;
  width: 100%;
}
.split.horizontal .gutter:before {
  content: "\2551";
}
.split.vertical .gutter:before {
  content: "\2550";
}
