@import "./vars/colors";
@import "./mixins/colors";

.viewer-notification {
  @import "colors";
  @include root-colors();

  ol,
  ul {
    padding-inline-start: 1em;
  }

  &.ant-notification-notice {
    background-color: $gray-lighter;
    color: $white;
    line-height: 1;

    .ant-btn {
      color: $white;
      border-color: $white;
      line-height: 1;
    }

    .ant-notification-close-x {
      color: $white;
    }

    .ant-notification-notice-message {
      line-height: 1;
    }

    .ant-notification-notice-description {
      margin-top: 0.4em;
      margin-bottom: 0.4em;
      li {
        margin: 0.4em;
      }
    }

    &.error .ant-notification-notice-icon {
      color: $error;
    }
    &.info .ant-notification-notice-icon {
      color: $info;
    }
    &.success .ant-notification-notice-icon {
      color: $success;
    }
    &.warning .ant-notification-notice-icon {
      color: $warning;
    }
  }
}
