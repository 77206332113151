@mixin typography-inherits {
  direction: inherit;
  font-family: inherit;
  font-kerning: inherit;
  font-size: inherit;
  font-stretch: inherit;
  font-style: inherit;
  font-synthesis: inherit;
  font-variant: inherit;
  font-weight: inherit;
  hyphens: inherit;
  letter-spacing: inherit;
  line-break: inherit;
  overflow-wrap: inherit;
  quotes: inherit;
  tab-size: inherit;
  text-align: inherit;
  line-height: inherit;
  text-decoration: inherit;
  text-justify: inherit;
  text-indent: inherit;
  text-orientation: inherit;
  text-overflow: inherit;
  text-shadow: inherit;
  text-transform: inherit;
  text-underline-position: inherit;
  vertical-align: inherit;
  white-space: inherit;
  word-break: inherit;
  word-spacing: inherit;
  word-wrap: inherit;
  writing-mode: inherit;
}

@mixin typography-defaults {
  font-family: "SF Pro Display", sans-serif;
  direction: ltr;
  font-kerning: normal;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: style weight;
  font-variant: normal;
  font-weight: 400;
  hyphens: auto;
  letter-spacing: normal;
  line-break: loose;
  line-height: 1;
  overflow-wrap: break-word;
  quotes: \201c \201d \2018 \2019;
  tab-size: 4;
  text-align: left;
  text-decoration: none;
  text-justify: inter-word;
  text-indent: 0;
  text-orientation: mixed;
  text-overflow: ellipsis;
  text-shadow: none;
  text-transform: none;
  text-underline-position: auto;
  vertical-align: middle;
  white-space: normal;
  word-break: break-all;
  word-spacing: 0;
  word-wrap: break-word;
  writing-mode: horizontal-tb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
