@import "./mixins/colors";
@import "./mixins/interactions";
@import "./mixins/typography";

.viewer-root {
  // NOTE(josiah): only apply the wildcard default rules in the root element to avoid
  // adding unintended behavior to other nested paths (i.e. modals)
  @include typography-defaults();
  @import "core";

  * {
    @include color-defaults();
    background-clip: padding-box;
  }

  [class*="ant-"] {
    @include interaction-defaults();
    @include typography-inherits();
    list-style-position: inherit;
  }

  border-style: none;
  border-width: 0;
  border-radius: 0;
  box-decoration-break: clone;
  box-sizing: border-box;
  cursor: auto;
  float: none;
  list-style-position: outside;
  overflow: auto;
  position: relative;
  z-index: 0;
}
